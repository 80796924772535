import React, { useState } from 'react';
import SubHeader from './subHeader';
import PhotoPopupContainer from "../december/PhotoPopupContainer";
import PhotoPopup2Container from "../december/PhotoPopup2Container";

const PopupComponent = ({  isPlaying, onPlayPause, isPopup, setIsPopup, popupIndex, statusDark, setStatusDark }) => {
    const [downloadUrl, setDownloadUrl] = useState('');
    
    return (
        <>
            <SubHeader
                isPlaying={isPlaying}
                onPlayPause={onPlayPause}
                isPopup={isPopup}
                setIsPopup={setIsPopup}
                downloadUrl={downloadUrl}
                statusDark={statusDark}
                setStatusDark={setStatusDark}
            />
            
            {popupIndex === 0 ?
                <PhotoPopupContainer
                    downloadUrl={downloadUrl}
                    setDownloadUrl={setDownloadUrl}
                /> :
                popupIndex === 1 ?
                <PhotoPopup2Container
                    downloadUrl={downloadUrl}
                    setDownloadUrl={setDownloadUrl}
                /> :
                null
            }
        </>
    );
}

export default PopupComponent;