import { setCookie, getCookie } from './cookieHelper'

const agreeCookieKey = '__NJ_AGREE';

export const setIsAgree = _ => {
    setCookie(agreeCookieKey, 'Y');
}

export const isAgree = () => {
    return getCookie(agreeCookieKey) === 'Y' ? true : false;
}