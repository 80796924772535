import React, { useState, useEffect } from 'react';
import MainComponent from "./mainComponent";
import ListComponent from "./listComponent";
import DetailComponent from "./detailComponent";
import PopupComponent from "./popupComponent";

export const MainContainer = ({ onPlayPause, onNext, isPlaying, setIsPlaying, audioTitle, statusDark, setStatusDark, audioRef }) => {
    const [isList, setIsList] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [isPopup, setIsPopup] = useState(false);
    const [popupIndex, setPopupIndex] = useState(0);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const videos = {
        hyein: [
            '/videos/hyein/HI_001(in).mp4',
            '/videos/hyein/HI_002(walk).mp4',
            '/videos/hyein/HI_003(jump).mp4',
            '/videos/hyein/HI_004(dance).mp4',
            '/videos/hyein/HI_005(pose).mp4',
            '/videos/hyein/HI_006(out).mp4'
        ],
        haerin: [
            '/videos/haerin/HR_001(in).mp4',
            '/videos/haerin/HR_002(walk).mp4',
            '/videos/haerin/HR_003(jump).mp4',
            '/videos/haerin/HR_004(dance).mp4',
            '/videos/haerin/HR_005(pose).mp4',
            '/videos/haerin/HR_006(out).mp4'
        ],
        danielle: [
            '/videos/danielle/DN_001(in).mp4',
            '/videos/danielle/DN_002(walk).mp4',
            '/videos/danielle/DN_003(jump).mp4',
            '/videos/danielle/DN_004(dance).mp4',
            '/videos/danielle/DN_005(pose).mp4',
            '/videos/danielle/DN_006(out).mp4'
        ],
        hanni: [
            '/videos/hanni/HN_001(in).mp4',
            '/videos/hanni/HN_002(walk).mp4',
            '/videos/hanni/HN_003(jump).mp4',
            '/videos/hanni/HN_004(dance).mp4',
            '/videos/hanni/HN_005(pose).mp4',
            '/videos/hanni/HN_006(out).mp4'
        ],
        minji: [
            '/videos/minji/MJ_001(in).mp4',
            '/videos/minji/MJ_002(walk).mp4',
            '/videos/minji/MJ_003(jump).mp4',
            '/videos/minji/MJ_004(dance).mp4',
            '/videos/minji/MJ_005(pose).mp4',
            '/videos/minji/MJ_006(out).mp4'
        ]
    }
    
    const setDynamicHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    useEffect(() => {
        window.addEventListener('resize', setDynamicHeight);
        
        return () => {
            window.removeEventListener('resize', setDynamicHeight);
        };
    });
    
    return (
        <>
            {isList ?
                <ListComponent
                    isList={isList}
                    setIsList={setIsList}
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    isPlaying={isPlaying}
                    onPlayPause={onPlayPause}
                    videos={videos}
                    setSelectedVideos={setSelectedVideos}
                    statusDark={statusDark}
                    setStatusDark={setStatusDark}
                /> :
            isDetail ?
                <DetailComponent
                    isList={isList}
                    setIsList={setIsList}
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    isPlaying={isPlaying}
                    onPlayPause={onPlayPause}
                    selectedVideos={selectedVideos}
                    statusDark={statusDark}
                    setStatusDark={setStatusDark}
                    setIsPlaying={setIsPlaying}
                    audioRef={audioRef}
                /> :
            isPopup ?
                <PopupComponent
                    isPlaying={isPlaying}
                    onPlayPause={onPlayPause}
                    isList={isList}
                    setIsList={setIsList}
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    isPopup={isPopup}
                    setIsPopup={setIsPopup}
                    popupIndex={popupIndex}
                /> :
                <MainComponent
                    onPlayPause={onPlayPause}
                    onNext={onNext}
                    isList={isList}
                    setIsList={setIsList}
                    isPlaying={isPlaying}
                    isPopup={isPopup}
                    setIsPopup={setIsPopup}
                    popIndex={setPopupIndex}
                    setPopupIndex={setPopupIndex}
                    audioTitle={audioTitle}
                />
            }
        </>
    )
}