import React, { useState, useEffect } from "react";
import { setIsLogin } from "../../../helpers/authHelper";
import crypto from "crypto";

const LoginComponent = (props) => {
    const { history } = props;
    const pwd = "W96aapMRYLV+GPb+N3G82xNvNBD0LH4H9D0qtYk8rDDuUWQY2AE9L2wcmf5d031t5PdJjl1mxGAS8dzpHlTixw==";
    const [passwd, setPasswd] = useState("");

    const makeEncryptedPassword = async (password) => {
        const keyBuffer = await new Promise((resolve, reject) => {
            crypto.pbkdf2(password, "ADOR_NJ", 256, 64, "sha512", (err, key) => {
                if (!err) {
                    resolve(key);
                } else {
                    reject(err);
                }
            });
        }).catch((err) => {
            console.error(err);
            return null;
        });

        if (!keyBuffer) {
            return null;
        }

        return keyBuffer.toString("base64");
    };

    const onKeyPress = async (e) => {
        if (e.key === "Enter") {
            const hashedPwd = await makeEncryptedPassword(passwd);
            console.log(hashedPwd)
            if (hashedPwd === pwd) {
                setIsLogin();
                history.push("/");
            } else {
                alert("비밀번호를 확인해 주세요.");
            }
        }
    };

    useEffect((_) => {
        if (process.env.REACT_APP_ENV === "prod") {
            history.push("/");
        }
    }, [history]);

    return (
        <div
            style={{
                display: "flex",
                padding: "150px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                border: "1px solid lightgrey",
                borderRadius: "5px",
            }}
        >
            <input
                value={passwd}
                onChange={(e) => setPasswd(e.target.value)}
                id="password"
                type="password"
                name="password"
                placeholder="비밀번호 입력 후 엔터"
                style={{
                    display: "block",
                    marginBottom: "3px",
                    padding: "3px",
                    border: "1px solid lightgray",
                    borderRadius: "3px",
                }}
                onKeyPress={onKeyPress}
            />
        </div>
    );
};

export default LoginComponent;
