import React from 'react';

const BunniesCampComponent = props => {
    const { certificateUrl } = props;

    return (
        <main>
            <div className='con'>
                <div className='img'>
                    <img src={certificateUrl} />
                </div>
            </div>
        </main>
    )
}

export default BunniesCampComponent;