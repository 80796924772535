import React from 'react';

const NotFoundComponent = props => {
    // const { history } = props;
    //
    // const renderMainPage = () => {
    //      window.location.href = `https://${process.env.REACT_APP_DOMAIN}`;
    // }

    return (
        <div id='container' className=''>

        </div>
    );
}

export default NotFoundComponent;