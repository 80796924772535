/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Routes from './views/Routes';
import {isMobile} from 'react-device-detect';
import {isLogin} from 'helpers/authHelper';

// CSS
import 'assets/scss/common.scss';

const browserHistory = createBrowserHistory();

const setScreenSize = () => {
    let vh = window.innerHeight *
        0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
}

const authCheck = _ => {
    if (process.env.REACT_APP_ENV ===
        'dev' &&
        !isLogin()) {
        alert("로그인이 필요합니다.");
        browserHistory.push('/login')
    }
}

function App() {
    const [needToBePortrait, setNeedToBePortrait] = useState(false);
    
    useEffect(
        _ => {
            const init = async () => {
                if (isMobile) {
                    setNeedToBePortrait(window.orientation !==
                        0);
                }
                
                if ('onorientationchange' in
                    window) {
                    console.log('App:onorientationchange')
                    window.addEventListener('orientationchange', evt => {
                        if (isMobile) {
                            setNeedToBePortrait(window.orientation !==
                                0);
                        }
                        
                    }, false)
                }
            }
            
            init();
            authCheck();
            setScreenSize();
            
            document.querySelectorAll('.btn-has-effect').forEach(button => {
                button.addEventListener('click', function(e) {
                    button.classList.remove('clicked');
                    
                    // 강제 리플로우를 트리거하여 애니메이션을 매번 재생하게 함
                    void button.offsetWidth;
                    
                    button.classList.add('clicked');
                });
            });
            
            window.addEventListener('resize', () => {
                setScreenSize();
            });
            
            return function cleanup() {
                window.removeEventListener('orientationchange');
            }
        },
        [],
    )
    
    return (
        <Router history={browserHistory}>
            <Routes />
        </Router>
    );
}

export default App;