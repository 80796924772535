import React, {useEffect} from 'react';
import SubHeader from "./subHeader";

const ListComponent = ({ isList, setIsList, isDetail, setIsDetail, isPlaying, onPlayPause, videos, setSelectedVideos, statusDark, setStatusDark }) => {
    const goToDetail = (member) => {
        setIsList(!isList);
        setIsDetail(!isDetail);
        setSelectedVideos(videos[member]);
        setStatusDark(true);
    }
    
    useEffect(() => {
        document.querySelector('.mockup').classList.remove('video');
    }, []);
    
    return (
        <>
            <SubHeader
                isList={isList}
                setIsList={setIsList}
                isDetail={isDetail}
                setIsDetail={setIsDetail}
                isPlaying={isPlaying}
                onPlayPause={onPlayPause}
                statusDark={statusDark}
                setStatusDark={setStatusDark}
            />
            
            <div className="member-list">
                <div className="item" onClick={() => goToDetail('hyein')}>
                    <img src="/imgs/common/hyein.png" alt="Hyein"/>
                </div>
                <div className="item" onClick={() => goToDetail('haerin')}>
                    <img src="/imgs/common/haerin.png" alt="Haerin"/>
                </div>
                <div className="item" onClick={() => goToDetail('danielle')}>
                    <img src="/imgs/common/danielle.png" alt="Danielle"/>
                </div>
                <div className="item" onClick={() => goToDetail('hanni')}>
                    <img src="/imgs/common/hanni.png" alt="Hanni"/>
                </div>
                <div className="item" onClick={() => goToDetail('minji')}>
                    <img src="/imgs/common/minji.png" alt="Minji"/>
                </div>
            </div>
        </>
    );
}

export default ListComponent;