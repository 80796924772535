import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GetUpEventComponent from './GetUpEventComponent';

const imgObjList = {
    'packshot_1': {
        'pc': '/imgs/get-up-event/pc/NJ_getup_packshot_1.jpg',
        'mb': '/imgs/get-up-event/mb/NJ_getup_packshot_1.jpg',
    },
    'packshot_2': {
        'pc': '/imgs/get-up-event/pc/NJ_getup_packshot_2.jpg',
        'mb': '/imgs/get-up-event/mb/NJ_getup_packshot_2.jpg',
    },
    'packshot_3': {
        'pc': '/imgs/get-up-event/pc/NJ_getup_packshot_3.jpg',
        'mb': '/imgs/get-up-event/mb/NJ_getup_packshot_3.jpg',
    }
}

const GetUpEventContainer = props => {
    const { history } = props;
    const [isInit, setIsInit] = useState(false);
    const [imgObj, setImgObj] = useState(null);

    const { id } = useParams();


    useEffect(() => {
        const newImgObj = imgObjList[id];

        if (!newImgObj) {
            history.push('/');
        } else {
            setImgObj(newImgObj);
            setIsInit(true);
        }
    }, [])

    return (
        <div id="container" className='get-up-event'>
            {isInit ?
                <GetUpEventComponent
                    imgObj={imgObj}
                />
                : null}
        </div>
    )
}

export default GetUpEventContainer;