import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import CookiePopupComponent from "../popup/CookiePopupComponent";

const LayoutContainer = ({ containerClassName, children }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTrack, setCurrentTrack] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [muted, setMuted] = useState(false);
    const [audioTitle, setAudioTitle] = useState('Supernatural');
    const [statusDark, setStatusDark] = useState(false);
    const [time, setTime] = useState({
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
        seconds: new Date().getSeconds()
    });
    const audioTitles = [
        'Supernatural',
        'Right Now',
        'How Sweet',
        'Bubble Gum',
    ];
    const audioSources = [
        '/music/NJ_Supernatural_Instrumental.wav',
        '/music/NJ_Right_Now_Instrumental.wav',
        '/music/NJ_How_Sweet_Instrumental.wav',
        '/music/NJ_Bubble_Gum_Instrumental.wav',
    ];
    
    useEffect(() => {
        const playButton = document.querySelector('.btn-play');
        const tick = () => {
            const now = new Date();
            setTime({
                hours: now.getHours(),
                minutes: now.getMinutes(),
                seconds: now.getSeconds()
            });
        };
        
        if (audioRef.current && !isFirstLoad) {
            setAudioTitle(audioTitles[currentTrack]);
            audioRef.current.src = audioSources[currentTrack];
            audioRef.current.play().catch(error => {
                console.error('Error playing audio:', error);
            });
            
            setIsPlaying(true);
            if ( playButton ) {
                playButton.classList.add('playing');
            }
        } else {
            setIsFirstLoad(false);
        }
        
        tick();
        
        const intervalId = setInterval(tick, 1000);
        
        return () => clearInterval(intervalId);
    }, [currentTrack]);
    
    const handlePlayPause = () => {
        if (audioRef.current) {
            if (audioRef.current.paused) {
                audioRef.current.play().catch(error => {
                    console.error('Error playing audio:', error);
                });
                setIsPlaying(true);
            } else {
                audioRef.current.pause();
                setIsPlaying(false);
            }
        }
    };
    
    const handleNext = () => {
        const nextTrack = (currentTrack + 1) % audioSources.length;
        setCurrentTrack(nextTrack);
    };
    
    return (
        <>
            <audio ref={audioRef} src={audioSources[currentTrack]} muted={muted} onEnded={handleNext}></audio>
            <main>
                <div id="container" className={containerClassName}>
                    <div className="content">
                        {/*<img className="bg" src="/imgs/common/pc-phone.png" alt="bg"/>
                        <div className="overlay-bg-left"></div>
                        <div className="overlay-bg-right"></div>
                        <div className="overlay-bg-top"></div>
                        <div className="overlay-bg-bottom"></div>
                        <div className={`overlay-bg-bar-time ${needWhiteStatusBar ? 'white' : ''} en`}>{barTime}</div>
                        <div className={`overlay-bg-bar-status  ${needWhiteStatusBar ? 'white' : ''} `}></div>*/}
                        
                        <div className="mockup">
                            <div className="mockup-inner">
                                <div className={statusDark ? 'status-bar dark' : 'status-bar'}>
                                    <div className="clock">
                                        <div className="hour">{time.hours < 10 ? `0${time.hours}` : time.hours}</div>
                                        :
                                        <div className="minute">{time.minutes < 10 ? `0${time.minutes}` : time.minutes}</div>
                                    </div>
                                    
                                    {statusDark ?
                                        <img src="/imgs/common/status-bar-black.png" alt=""/>
                                        :
                                        <img src="/imgs/common/status-bar.png" alt=""/>
                                    }
                                </div>
                                
                                <div className="mockup-content">
                                    {React.cloneElement(children, { onPlayPause: handlePlayPause, onNext: handleNext, isPlaying: isPlaying, muted: muted, setIsPlaying: setIsPlaying, setMuted: setMuted, audioTitle: audioTitle, statusDark: statusDark, setStatusDark: setStatusDark, audioRef })}
                                </div>
                                
                                <div className="mockup-footer">
                                    <img src="/imgs/common/app-bottom.png" alt=""/>
                                    
                                    <div className="footer-group">
                                        <p className="copyright">©2024 ADOR. All Rights Reserved.</p>
                                        <Link to="/agree">쿠키사용방침</Link>
                                    </div>
                                </div>
                                
                                {containerClassName !== 'cookie-agree' ? (
                                    <CookiePopupComponent />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default LayoutContainer;