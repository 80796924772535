import React, { useEffect, useState } from 'react';
import * as LanguageCodeHelper from 'helpers/languageCodeHelper';
import { Link } from 'react-router-dom'

const CookieAgreeComponent = props => {
    /*const { history } = props;*/
    const [languageCode, setLanguageCode] = useState('en');

    useEffect(_ => {
        const languageCode = LanguageCodeHelper.getBrowserLanguageCode('en');
        setLanguageCode(languageCode);
    }, [])
    return (
        <main>
            <div className="con">

                <div className={`text ${languageCode}`}>
                    <div className='ko'>
                        <div>
                            <Link to="/">
                                뒤로
                            </Link>
                            <br /><br />
                        </div>
                        <strong>쿠키 사용 방침</strong><br />
                        <br />
                        본 쿠키 사용 방침(이하 “<strong>본 방침</strong>”)은 (주) 어도어(이하 “<strong>서비스”</strong> 또는 “<strong>당사</strong>”)가 발행하였습니다.<br />
                        <br />
                        <strong>본 방침이 적용되는 웹사이트들</strong><br />
                        <br />
                        당사가 운영하거나 통제하며 본 방침이 적용되는 웹사이트들(이하 통칭하여 “대상웹사이트”)은 다음과 같습니다.<br />
                        <br />
                        <ul>
                            <li><a href="https://newjeans.kr" target="_blank" rel="noopener noreferrer" >https://newjeans.kr</a></li>
                        </ul>
                        <br />
                        <strong>쿠키의 정의</strong><br />
                        <br />
                        쿠키는 귀하가 웹사이트를 방문할 때 귀하의 접속기기에 저장되는 소규모 텍스트 파일입니다. 일반적으로 쿠키는 웹사이트를 작동시키고 웹사이트 내 귀하의 활동
                        상황을
                        추적할 뿐만 아니라 귀하의 로그인 정보,
                        선호도, 관심사 등을 기억하는 데 사용됩니다.<br />
                        <br />
                        쿠키의 유형은 다양하며 저마다 출처와 기능 및 수명에 따라 구분됩니다. 쿠키의 중요한 특성은 다음과 같습니다.<br />
                        <br />
                        <ul>
                            <li>
                                <strong>당사자 쿠키(first party cookies)</strong>는 귀하가 방문하고 있는 웹사이트에서 설치하는 쿠키입니다. 이와 달리,
                                <strong>제3자 쿠키(third party cookies)</strong>는 귀하가 방문하고 있는
                                웹사이트를 제외한 다른 웹사이트에서 설치하는 쿠키입니다. 당사는 제3자의 데이터 수집 또는 추가적인 데이터 이용에 대하여 통제하지 않음에
                                유의하시기
                                바랍니다.
                            </li>
                            <li>
                                <strong>필수 쿠키</strong>는 웹사이트의 기술적인 운영(예를 들면, 귀하가 웹사이트 이곳 저곳을 둘러보며 웹사이트에서 제공하는 각종 기능을 이용할 수 있게 하는데)에 필수적인 쿠키입니다.
                            </li>
                            <li>
                                <strong>분석/성능 쿠키</strong>는 방문자 수, 방문자의 체류 시간 및 오류 메시지 등 웹사이트의 성능에 관한 데이터를 수집하는 쿠키입니다.
                            </li>
                            <li>
                                <strong>기능 및 프로필 쿠키</strong>는 귀하가 어떠한 웹사이트에 방문하면서 선택한 사항들(예를 들면, 언어, 지역, 로그인 정보 등)을 기억함으로써 해당
                                웹사이트의 사용 편의성을 증진하는 쿠키입니다.
                            </li>
                            <li>
                                <strong>광고/타게팅 쿠키</strong>는 웹사이트에서 귀하에게 맞춤형 광고를 전송할 수 있게 하는 쿠키입니다.
                            </li>
                            <li>
                                <strong>세션 쿠키</strong>는 귀하가 브라우저를 종료할 시 삭제되는 일시적인 쿠키입니다. 이와 달리, <strong>영구 쿠키</strong>는 귀하가 수동으로 삭제하기 전까지 혹은 귀하의
                                브라우저가 해당 쿠키 파일에 명시된 지속기간 만료 시점에 삭제하기 전까지 귀하의 접속기기에 계속 설치되어 있는 쿠키를 말합니다.
                            </li>
                        </ul><br />
                        <br />
                        쿠키의 모든 측면에 대한 보다 자세한 사항은 <a href="www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a> 에서 확인하실 수
                        있습니다. 당사는 위 제3자 웹사이트와 아무런 제휴 관계를 맺고 있지 않고 동 웹사이트에 대하여 어떠한 책임도 부담하지 않음에 유의하시기 바랍니다.<br />
                        <br />
                        <strong>당사가 쿠키를 사용하는 이유</strong><br />
                        <br />
                        당사는 다음의 각 목적을 위하여 쿠키를 사용할 수 있습니다.<br />
                        <br />
                        <ul>
                            <li>
                                방문자 구분</li>
                            <li>
                                대상웹사이트의 용도 및 기능 개선</li>
                            <li>
                                귀하의 구매 주문 처리</li>
                            <li>
                                대상웹사이트 및 당사의 제품들을 귀하의 필요 및 선호도에 따라 맞춤 설계</li>
                            <li>
                                대상웹사이트가 이용되는 방식을 분석하고 익명 처리된 관련 통계자료를 집적
                            </li>
                        </ul><br />
                        <br />
                        당사는 수집한 정보를 방문자 프로필을 생성하는 데 이용하지 않습니다.<br />
                        <br />
                        당사가 사용하는 쿠키 유형<br />
                        <br />
                        대상웹사이트에서 사용되는 쿠키들에 대한 개요는 다음 표와 같습니다.<br />
                        <br />
                        <table>
                            <thead>
                                <tr>
                                    <th>명칭</th>
                                    <th>
                                        당사자 쿠키 또는<br />
                                        제3자 쿠키 여부
                                    </th>
                                    <th>
                                        지속기간
                                    </th>
                                    <th>
                                        목적
                                    </th>
                                    <th>
                                        수집 데이터 유형
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>_ga</td>
                                    <td>제3자 쿠키(Google Analytics)</td>
                                    <td>180일</td>
                                    <td>분석/성능 쿠키</td>
                                    <td>사용자의 세션과 캠페인 정보를 저장하는데 사용</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        쿠키와 관련된 경우를 비롯하여 기타 목적을 위한 귀하의 개인정보에 대한 처리는 당사의 <a
                            href="https://hybecorp.com/kor/privacy" target="_blank" rel="noopener noreferrer">개인정보 처리방침</a>에 명시된 규정에
                        의하여 규율됩니다.

                        아울러, 당사는 쿠키에 추가하거나 쿠키와 연동하는 방식으로 웹 비콘(또는 클리어GIF)과 기타 유사한 기술들을 사용할 수도 있습니다. 웹 비콘은 통상적으로
                        웹사이트나
                        이메일에 설치되는 투명한 그래픽
                        이미지(일반적으로 1픽셀 X 1픽셀 크기의 작은 이미지)로서 당사가 대상웹사이트 방문자들의 이용행태를 이해하는 데 도움을 줍니다. 웹 비콘에 대한 보다 자세한
                        사항은
                        <a
                            href="http://www.allaboutcookies.org/faqs/beacons.html">http://www.allaboutcookies.org/faqs/beacons.html</a>.에서
                        확인하실 수 있습니다. 당사는 위 제3자 웹사이트와 아무런 제휴 관계를 맺고 있지 않고 동 웹사이트에 대하여 어떠한 책임도 부담하지 않음에 유의하시기
                        바랍니다.<br />
                        <br />
                        대상웹사이트는 Google, Inc. (이하 “구글”)이 제공하는 웹 분석 서비스인 구글 애널리틱스(Google Analytics)를 사용합니다. 구글
                        애널리틱스의 쿠키가 귀하의 웹사이트 이용 내역에 관하여 생성한 정보(귀하의 IP 주소 포함)는 구글의 미국 소재 서버에 전송되어 저장됩니다.<br />
                        <br />
                        구글은 귀하의 웹사이트 이용 활동을 평가하고, 웹사이트 운영자들을 위한 웹사이트 이용 실태 보고서를 작성하고, 웹사이트 및 인터넷 이용 활동과 관련된 기타
                        서비스를 제공하기 위한 목적으로 위와 같이 수집된 정보를 사용합니다. 또한, 법률에 따라 요구되거나 제3자가 구글을 대신하여 정보를 처리하는 경우, 구글은 위와 같이 수집된 정보를 해당 제3자에게 전송할 수
                        있습니다. 구글은 귀하의 IP 주소를 구글이 보유하고 있는 기타 데이터와 연계시키지 않습니다. 귀하는 브라우저 설정을 적절하게 선택하여 쿠키 사용을 거부할 수 있습니다. 단, 쿠키 사용 거부 시
                        대상웹사이트의 기능을 최대로 사용할 수 없게 됨을 유의하시기 바랍니다. <br />
                        귀하는 대상웹사이트를 이용함으로써 구글이 위와 같은 방식 및 목적으로 귀하에 관한 데이터를 처리하는 데 동의하는 것으로 간주됩니다.<br />
                        <br />
                        관련 내용에 대한 보다 자세한 사항은 <a href="http://www.google.com/analytics/"
                            target="_blank" rel="noopener noreferrer">http://www.google.com/analytics/</a>에서 확인하실 수 있습니다.<br />
                        <br />
                        <br />
                        귀하가 쿠키와 웹 비콘을 통제할 수 있는 방법
                        <br />
                        대부분의 인터넷 브라우저는 쿠키 설치를 자동적으로 허용하도록 설정되어 있습니다. 귀하의 브라우저에 따라 쿠키 설치를 허용하기 전에 귀하에게 경고를 하거나 쿠키
                        사용을
                        거부하도록 설정할 수 있습니다. 이러한
                        브라우저 설정 방법에 대한 보다 자세한 사항은 귀하의 브라우저에서 “도움말” 버튼(또는 이와 유사한 버튼)을 클릭하여 확인하시기 바랍니다.

                        쿠키 거부 설정 시 대상웹사이트 상에서의 이용 경험이 제한될 수 있습니다.
                        <br />
                        귀하가 다수의 접속기기로 대상웹사이트에 접속할 경우, 접속기기별 각 브라우저의 쿠키 사용 관련 설정을 귀하의 선호에 따라 설정할 필요가 있습니다.
                        <br />
                        쿠키 관리 방법에 대한 보다 자세한 사항은 <a href="http://www.allaboutcookies.org/manage-cookies/"
                            target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org/manage-cookies/</a>에서 확인하실 수 있습니다.
                        당사는 위
                        제3자 웹사이트와 아무런 제휴 관계를 맺고
                        있지 않고 동 웹사이트에 대하여 어떠한 책임도 부담하지 않음에 유의하시기 바랍니다.<br />
                        <br />
                        아울러, 귀하는 다음의 제3자 웹사이트를 방문하여 특정 업체의 쿠키를 선택함으로써 해당 쿠키를 거부(opt-out)할 수 있습니다. 당사는 아래 제3자
                        웹사이트들과
                        아무런 제휴 관계를 맺고 있지 않고 동
                        웹사이트들에 대하여 어떠한 책임도 부담하지 않음에 유의하시기 바랍니다.<br />
                        <a href="http://www.aboutads.info/choices/#completed"
                            target="_blank" rel="noopener noreferrer">http://www.aboutads.info/choices/#completed</a> 및 <a
                                href="http://www.youronlinechoices.com/"
                                target="_blank" rel="noopener noreferrer">http://www.youronlinechoices.com/</a><br />
                        <br />
                        최종 업데이트: [2022.7.22]
                    </div>
                    <div className='en'>
                        <div>
                            <Link to="/">
                                BACK
                            </Link>
                            <br /><br />
                        </div>
                        COOKIE NOTICE<br />
                        <br />
                        This Notice is issued by ADOR Inc. (“Services”, “we”, “us” and “our”).<br />
                        <br />
                        <strong>Websites to which this Notice applies</strong><br />
                        <br />
                        This Notice applies to the following websites that are operated or controlled by ADOR:<br />
                        <br />
                        <ul>
                            <li>
                                <a href="https://newjeans.kr" target="_blank" rel="noopener noreferrer">https://newjeans.kr</a>
                            </li>
                        </ul>
                        <br />
                        (together, the “<strong>Sites”</strong>)<br />
                        <br />
                        What are cookies?<br />
                        <br />
                        Cookies are small text files that may be stored on your device when you visit a website.<br />
                        They are generally used to
                        make websites work, to keep track of your movements within the website, to remember your
                        login details, to remember
                        your preferences and interests, and so on.<br />
                        <br />
                        There are different types of cookies, and they can be distinguished on the basis of
                        their
                        origin, function and
                        lifespan. Important characteristics of cookies include the following:<br />
                        <br />
                        <ul>
                            <li>
                                <strong>First party cookies</strong> are cookies that are placed by the website you are visiting, while
                                <strong>third party cookies</strong> are placed by a website other than the one you are visiting. Please note that we do not
                                control the collection or further use of data by third parties.
                            </li>
                            <li>
                                <strong>Essential cookies</strong> are necessary to allow the technical operation of a website (e.g.,they
                                enable you to move around on a website and to use its features).
                            </li>
                            <li>
                                <strong>Analytical/Performance Cookies</strong>collect data on the performance of a website such as the
                                number of visitors, the time spent on the website and error messages.
                            </li>
                            <li>
                                <strong>Functionality and Profile Cookies</strong> increase the usability of a website by remembering
                                your choices (e.g. language, region, login, and so on).
                            </li>
                            <li>
                                <strong>Advertising / Targeting Cookies</strong> enable a website to send you personalized advertising.
                            </li>
                            <li>
                                <strong>Session cookies</strong> are temporary cookies that are erased once you close your browser while
                                <strong>persistent or permanent cookies</strong> stay on your device until you manually delete them or until your browser deletes
                                them based on the duration
                                period specified in the persistent cookie file.

                            </li>
                        </ul>
                        More information on all aspects of cookies can be found on <a href="www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org.</a>Please note that ADOR has no
                        affiliation with, and is not responsible for, this third party website.
                        <br />
                        <strong>Why do we use cookies?</strong><br />
                        <br />
                        We may use cookies to:<br />
                        <ul>
                            <li>distinguish between visitors;</li>
                            <li>improve the use and the functionality of our Sites;</li>
                            <li>process your purchase orders;</li>
                            <li>tailor our Sites and products to your needs and preferences; and</li>
                            <li>analyse how our Sites are used and compile anonymous and aggregate statistics.</li>
                        </ul>
                        We do not use the collected information to create visitor profiles.<br />
                        <br />
                        <strong>What types of cookies do we use?</strong><br />
                        <br />
                        An overview of the cookies used on our Sites is set out in the following table.<br />
                        <br />
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>First Party or Third Party?</th>
                                    <th>Duration</th>
                                    <th>Purpose</th>
                                    <th>Types of data collected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>_ga</td>
                                    <td>Third Party(Google Analytics)</td>
                                    <td>180 days</td>
                                    <td>Analytical/Performance Cookies</td>
                                    <td>Used to store user session and campaign information</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        Please note that the processing of your personal data in connection with cookies, and
                        for other purposes, is governed by our [External Privacy Notice].<br />
                        <br />
                        In addition, we may also use web beacons (or clear GIFs) and other similar technologies
                        in
                        addition to, or in
                        combination with, cookies. A web beacon is typically a transparent graphic image
                        (usually 1
                        pixel x 1 pixel) that is
                        placed on a website or in an email and it helps us to understand the behaviour of
                        visitors
                        to our Sites. More
                        information on web beacons can be found at
                        <a href="http://www.allaboutcookies.org/faqs/beacons.html">
                            http://www.allaboutcookies.org/faqs/beacons.html.
                        </a>
                        Please note that ADOR
                        has no affiliation with, and is not responsible for, this third party website.<br />
                        <br />
                        Our Sites use Google Analytics, a web analytics service provided by Google, Inc.
                        ("Google").<br />
                        The information
                        generated by Google Analytics Cookies about your use of the website (including your IP
                        address) will be transmitted
                        to and stored by Google on servers in the United States.<br />
                        <br />
                        Google will use this information for the purpose of evaluating your use of our Sites,
                        compiling reports on website
                        activity for website operators and providing other services relating to website activity
                        and
                        internet usage. Google
                        may also transfer this information to third parties where required to do so by law, or
                        where
                        such third parties
                        process the information on Google's behalf. Google will not associate your IP address
                        with
                        any other data held by
                        Google. You may refuse the use of cookies by selecting the appropriate settings on your
                        browser, however please note
                        that if you do this you may not be able to use the full functionality of our Sites. By
                        using our Sites, you consent
                        to the processing of data about you by Google in the manner and for the purposes set out
                        above.
                        <br />
                        For more information please visit <a href="http://www.google.com/analytics/">http://www.google.com/analytics/</a><br />
                        <br />
                        <br />
                        How can you control cookies and web beacons?<br />
                        <br />
                        Most internet browsers are set to automatically accept cookies. Depending on your
                        browser, you can set your browser
                        to warn you before accepting cookies, or you can set it to refuse them. Please refer to
                        the 'help' button (or similar) on your browser to learn more about how you can do this.
                        <br />
                        Disabling cookies may impact your experience on our Sites.
                        <br />
                        If you use different devices to access our Sites, you will need to ensure that each
                        browser of each device is set to your cookie preference.
                        <br />
                        More information on how to manage cookies is available from:<br />
                        <a href="http://www.allaboutcookies.org/manage-cookies/">http://www.allaboutcookies.org/manage-cookies/</a>. Please
                        note that ADOR has no affiliation with, and is not responsible for, this third party
                        website.
                        <br />
                        In addition, you may opt-out from certain cookies by visiting the following third party
                        websites and selecting which company cookies you would like to opt-out from:<br />
                        <a href="http://www.aboutads.info/choices/#completed">http://www.aboutads.info/choices/#completed</a> and
                        <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/.</a> Please note that ADOR has no affiliation with, and is
                        not responsible for, these third party websites.<br />
                        <br />
                        <br />
                        <br />
                        Last updated: [2022.7.22]
                    </div>
                </div>
            </div>
        </main>
    )
}

export default CookieAgreeComponent;