const SET_IS_APP = 'common_layout/SET_IS_APP';
const SET_IS_MOBILE = 'common_layout/SET_IS_MOBILE';
const SET_IS_SHOW_INTRO = 'common_layout/SET_IS_SHOW_INTRO';


export const setIsApp = value => ({ type: SET_IS_APP, isApp: value });
export const setIsMobile = value => ({ type: SET_IS_MOBILE, isMobile: value });
export const setIsShowIntro = value => ({ type: SET_IS_SHOW_INTRO, isMobile: value });


const initialState = {
  isApp: false,
  isMobile: false,
  isShowIntro: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_APP:
      return {
        ...state,
        isApp: action.isApp
      };
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile
      };
    case SET_IS_SHOW_INTRO:
      return {
        ...state,
        isShowIntro: action.isShowIntro
      };
    default:
      return state;
  }
}