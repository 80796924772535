import React, { useState, useEffect } from 'react';
import OmgComponent from './OmgComponent';

import qrUrl from 'data/omg/qr-url.json'

const OmgContainer = props => {
    const { history, containerClassName } = props;

    const [isInit, setIsInit] = useState(false);
    const [videoId, setVideoId] = useState('');

    useEffect(_ => {
        console.log('OmgContainer:init', history);
        console.log(history);

        const pathName = history.location.pathname;
        const videoId = qrUrl[pathName];

        setVideoId(videoId);

        setIsInit(false);
    }, []);

    return (
        <div id="container" className={containerClassName}>
            <OmgComponent
                videoId={videoId}>
            </OmgComponent>
        </div>
    )

}

export default OmgContainer;