import React, { useRef } from 'react';

import PhotoPopupComponent from './PhotoPopupComponent';

import { Navigation } from 'swiper';

const imageList = [
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_34.jpg",
        "fileName": "NJ_HowSweet_34"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_35.jpg",
        "fileName": "NJ_HowSweet_35"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_36.jpg",
        "fileName": "NJ_HowSweet_36"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_37.jpg",
        "fileName": "NJ_HowSweet_37"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_38.jpg",
        "fileName": "NJ_HowSweet_38"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_39.jpg",
        "fileName": "NJ_HowSweet_39"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_40.jpg",
        "fileName": "NJ_HowSweet_40"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_41.jpg",
        "fileName": "NJ_HowSweet_41"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_42.jpg",
        "fileName": "NJ_HowSweet_42"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_43.jpg",
        "fileName": "NJ_HowSweet_43"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_44.jpg",
        "fileName": "NJ_HowSweet_44"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_45.jpg",
        "fileName": "NJ_HowSweet_45"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_46.jpg",
        "fileName": "NJ_HowSweet_46"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_47.jpg",
        "fileName": "NJ_HowSweet_47"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_48.jpg",
        "fileName": "NJ_HowSweet_48"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_49.jpg",
        "fileName": "NJ_HowSweet_49"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_50.jpg",
        "fileName": "NJ_HowSweet_50"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_51.jpg",
        "fileName": "NJ_HowSweet_51"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_52.jpg",
        "fileName": "NJ_HowSweet_52"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_53.jpg",
        "fileName": "NJ_HowSweet_53"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_54.jpg",
        "fileName": "NJ_HowSweet_54"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_55.jpg",
        "fileName": "NJ_HowSweet_55"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_56.jpg",
        "fileName": "NJ_HowSweet_56"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_57.jpg",
        "fileName": "NJ_HowSweet_57"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_58.jpg",
        "fileName": "NJ_HowSweet_58"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_59.jpg",
        "fileName": "NJ_HowSweet_59"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_60.jpg",
        "fileName": "NJ_HowSweet_60"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_61.jpg",
        "fileName": "NJ_HowSweet_61"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_01.jpg",
        "fileName":"NJ_BubbleGum_01"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_02.jpg",
        "fileName":"NJ_BubbleGum_02"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_03.jpg",
        "fileName":"NJ_BubbleGum_03"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_04.jpg",
        "fileName":"NJ_BubbleGum_04"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_05.jpg",
        "fileName":"NJ_BubbleGum_05"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_06.jpg",
        "fileName":"NJ_BubbleGum_06"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_07.jpg",
        "fileName":"NJ_BubbleGum_07"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_08.jpg",
        "fileName":"NJ_BubbleGum_08"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_09.jpg",
        "fileName":"NJ_BubbleGum_09"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_10.jpg",
        "fileName":"NJ_BubbleGum_10"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_11.jpg",
        "fileName":"NJ_BubbleGum_11"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_12.jpg",
        "fileName":"NJ_BubbleGum_12"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_13.jpg",
        "fileName":"NJ_BubbleGum_13"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_14.jpg",
        "fileName":"NJ_BubbleGum_14"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_15.jpg",
        "fileName":"NJ_BubbleGum_15"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_16.jpg",
        "fileName":"NJ_BubbleGum_16"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_17.jpg",
        "fileName":"NJ_BubbleGum_17"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_18.jpg",
        "fileName":"NJ_BubbleGum_18"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_19.jpg",
        "fileName":"NJ_BubbleGum_19"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_20.jpg",
        "fileName":"NJ_BubbleGum_20"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_21.jpg",
        "fileName":"NJ_BubbleGum_21"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_22.jpg",
        "fileName":"NJ_BubbleGum_22"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_23.jpg",
        "fileName":"NJ_BubbleGum_23"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_24.jpg",
        "fileName":"NJ_BubbleGum_24"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_25.jpg",
        "fileName":"NJ_BubbleGum_25"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_26.jpg",
        "fileName":"NJ_BubbleGum_26"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_27.jpg",
        "fileName":"NJ_BubbleGum_27"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_28.jpg",
        "fileName":"NJ_BubbleGum_28"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_29.jpg",
        "fileName":"NJ_BubbleGum_29"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_30.jpg",
        "fileName":"NJ_BubbleGum_30"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_31.jpg",
        "fileName":"NJ_BubbleGum_31"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_32.jpg",
        "fileName":"NJ_BubbleGum_32"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_33.jpg",
        "fileName":"NJ_BubbleGum_33"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_34.jpg",
        "fileName":"NJ_BubbleGum_34"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_35.jpg",
        "fileName":"NJ_BubbleGum_35"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_36.jpg",
        "fileName":"NJ_BubbleGum_36"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_37.jpg",
        "fileName":"NJ_BubbleGum_37"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_38.jpg",
        "fileName":"NJ_BubbleGum_38"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_39.jpg",
        "fileName":"NJ_BubbleGum_39"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_40.jpg",
        "fileName":"NJ_BubbleGum_40"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_41.jpg",
        "fileName":"NJ_BubbleGum_41"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_42.jpg",
        "fileName":"NJ_BubbleGum_42"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_43.jpg",
        "fileName":"NJ_BubbleGum_43"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_44.jpg",
        "fileName":"NJ_BubbleGum_44"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_45.jpg",
        "fileName":"NJ_BubbleGum_45"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_46.jpg",
        "fileName":"NJ_BubbleGum_46"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_47.jpg",
        "fileName":"NJ_BubbleGum_47"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_48.jpg",
        "fileName":"NJ_BubbleGum_48"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_49.jpg",
        "fileName":"NJ_BubbleGum_49"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_50.jpg",
        "fileName":"NJ_BubbleGum_50"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_51.jpg",
        "fileName":"NJ_BubbleGum_51"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_52.jpg",
        "fileName":"NJ_BubbleGum_52"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_53.jpg",
        "fileName":"NJ_BubbleGum_53"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_54.jpg",
        "fileName":"NJ_BubbleGum_54"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_55.jpg",
        "fileName":"NJ_BubbleGum_55"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_56.jpg",
        "fileName":"NJ_BubbleGum_56"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_57.jpg",
        "fileName":"NJ_BubbleGum_57"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_58.jpg",
        "fileName":"NJ_BubbleGum_58"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_59.jpg",
        "fileName":"NJ_BubbleGum_59"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_60.jpg",
        "fileName":"NJ_BubbleGum_60"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_61.jpg",
        "fileName":"NJ_BubbleGum_61"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_62.jpg",
        "fileName":"NJ_BubbleGum_62"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_63.jpg",
        "fileName":"NJ_BubbleGum_63"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_64.jpg",
        "fileName":"NJ_BubbleGum_64"
    },
    {
        "url":"/imgs/bubblegum/photo/NJ_BubbleGum_65.jpg",
        "fileName":"NJ_BubbleGum_65"
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_01.jpg",
        "fileName": "NJ_HowSweet_01",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_02.jpg",
        "fileName": "NJ_HowSweet_02",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_03.jpg",
        "fileName": "NJ_HowSweet_03",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_04.jpg",
        "fileName": "NJ_HowSweet_04",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_05.jpg",
        "fileName": "NJ_HowSweet_05",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_06.jpg",
        "fileName": "NJ_HowSweet_06",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_07.jpg",
        "fileName": "NJ_HowSweet_07",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_08.jpg",
        "fileName": "NJ_HowSweet_08",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_09.jpg",
        "fileName": "NJ_HowSweet_09",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_10.jpg",
        "fileName": "NJ_HowSweet_10",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_11.jpg",
        "fileName": "NJ_HowSweet_11",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_12.jpg",
        "fileName": "NJ_HowSweet_12",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_13.jpg",
        "fileName": "NJ_HowSweet_13",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_14.jpg",
        "fileName": "NJ_HowSweet_14",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_15.jpg",
        "fileName": "NJ_HowSweet_15",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_16.jpg",
        "fileName": "NJ_HowSweet_16",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_17.jpg",
        "fileName": "NJ_HowSweet_17",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_18.jpg",
        "fileName": "NJ_HowSweet_18",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_19.jpg",
        "fileName": "NJ_HowSweet_19",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_20.jpg",
        "fileName": "NJ_HowSweet_20",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_21.jpg",
        "fileName": "NJ_HowSweet_21",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_22.jpg",
        "fileName": "NJ_HowSweet_22",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_23.jpg",
        "fileName": "NJ_HowSweet_23",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_24.jpg",
        "fileName": "NJ_HowSweet_24",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_25.jpg",
        "fileName": "NJ_HowSweet_25",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_26.jpg",
        "fileName": "NJ_HowSweet_26",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_27.jpg",
        "fileName": "NJ_HowSweet_27",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_28.jpg",
        "fileName": "NJ_HowSweet_28",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_29.jpg",
        "fileName": "NJ_HowSweet_29",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_30.jpg",
        "fileName": "NJ_HowSweet_30",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_31.jpg",
        "fileName": "NJ_HowSweet_31",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_32.jpg",
        "fileName": "NJ_HowSweet_32",
    },
    {
        "url": "/imgs/howsweet/photo/NJ_HowSweet_33.jpg",
        "fileName": "NJ_HowSweet_33",
    }
]

const PhotoPopupContainer = props => {

    const { onClickBtnClosePopup, downloadUrl, setDownloadUrl } = props;

    // const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const slideBtnPrevRef = useRef(null);
    
    const slideBtnNextRef = useRef(null);

    const swiperParams = {
        // clickable: true,
        slidesPerView: 1,
        modules: [Navigation],
        navigation: {
            prevEl: '.btn-slide-prev',
            nextEl: '.btn-slide-next'
        }
    }

    const onAfterInitSlick = (evt) => {
        console.log('onAfterInit')
        console.log(evt);
    }
    
    const onSlideChangeSlick = (evt) => {
        // console.log(evt);
        // console.log('activeIndex', evt.activeIndex);
        // console.log('previousIndex', evt.previousIndex);
        // console.log('realIndex', evt.realIndex);

        // setCurrentImageIndex(evt.realIndex);

        // console.log(window.location);

        const url = window.location.origin + imageList[evt.realIndex].url;

        setDownloadUrl(url);
    }

    return (
        <PhotoPopupComponent
            onClickBtnClosePopup={onClickBtnClosePopup}
            onAfterInitSlick={onAfterInitSlick}
            onSlideChangeSlick={onSlideChangeSlick}
            downloadUrl={downloadUrl}
            swiperParams={swiperParams}
            imageList={imageList}
            slideBtnPrevRef={slideBtnPrevRef}
            slideBtnNextRef={slideBtnNextRef}
        />
    )
}

export default PhotoPopupContainer;