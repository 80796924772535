import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';

const customHistory = createBrowserHistory();

let middlewares = [];
if (process.env.REACT_APP_ENV === 'prod') {
  middlewares = [...middlewares, ReduxThunk.withExtraArgument({ history: customHistory })]
} else {
  middlewares = [...middlewares, ReduxThunk.withExtraArgument({ history: customHistory }), logger]
}

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      ...middlewares
    )
  )
);

/*
* 운영 환경에서 console 무력화
*/
if (process.env.REACT_APP_ENV === "prod") {
  console.log = () => { }
  console.debug = () => { }
  console.info = () => { }
  console.warn = () => { }
  console.error = () => { }
}
console.log(process.env.REACT_APP_ENV);
console.log(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
