import React, { useEffect } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import ReactGA from "react-ga4";
import ErrorBoundary from "./ErrorBoundary";

import { LayoutContainer } from './commons';

import {
    // 로그인
    LoginContainer as LoginPage,

    // Cookie Agree
    CookieAgreeContainer as CookieAgreePage,

    // Omg
    OmgContainer as OmgPage,

    // GetUp Event
    GetUpEventContainer as GetUpEventPage,

    // BunniesCamp
    BunniesCampContainer as BunniesCampPage,

    DecemberContainer as DecemberPage,

    // 메인
    MainContainer

} from "./pages";

const Routes = (props) => {
    const { history } = props;
    useEffect((_) => {
        // GA4 PageView 수집
        if (process.env.REACT_APP_ENV === "prod") {
            ReactGA.send("pageview");

            history.listen((location, action) => {
                ReactGA.send("pageview");
            });
        }

        return function cleanup() { };
    }, []);

    return (
        <ErrorBoundary>
            <Switch>
                <Route
                    exact
                    path="/login"
                    render={(props) => (
                        <LayoutContainer containerClassName="login">
                            <LoginPage {...props} />
                        </LayoutContainer>
                    )}
                />

                <OmgPage
                    containerClassName={"omg"}
                    history={history}
                    exact
                    path="/omg/:member/:seq/:id"
                />

                <Route path="/getup/:id/new"
                    history={history}
                    component={GetUpEventPage}
                />

                <Route path="/bunniescamp/:id"
                    history={history}
                    component={BunniesCampPage}
                />

                {/* <DecemberPage containerClassName={"december"} exact path="/" /> */}

                {/* 쿠키 동의 페이지 */}
                <Route
                    exact
                    path="/agree"
                    render={(props) => (
                        <LayoutContainer containerClassName="cookie-agree">
                            <CookieAgreePage {...props} />
                        </LayoutContainer>
                    )}
                />

                <Route
                    exact
                    path="/"
                    render={(props) => (
                        <LayoutContainer containerClassName="home">
                            <MainContainer {...props} />
                        </LayoutContainer>
                    )}
                />

                <Redirect to="/" />
            </Switch>
        </ErrorBoundary>
    );
};

export default withRouter(Routes);
