import { combineReducers } from 'redux';

import {
    layout as commonLayout,
} from './common'

const rootReducer = combineReducers({
    commonLayout,
});

export default rootReducer;