import React, { useRef } from 'react';

import PhotoPopup2Component from './PhotoPopup2Component';

import { Navigation } from 'swiper';

const imageList = [
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_66.jpg",
    "filename": "NJ_Supernatural_66"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_67.jpg",
    "filename": "NJ_Supernatural_67"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_68.jpg",
    "filename": "NJ_Supernatural_68"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_69.jpg",
    "filename": "NJ_Supernatural_69"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_70.jpg",
    "filename": "NJ_Supernatural_70"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_71.jpg",
    "filename": "NJ_Supernatural_71"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_72.jpg",
    "filename": "NJ_Supernatural_72"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_73.jpg",
    "filename": "NJ_Supernatural_73"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_74.jpg",
    "filename": "NJ_Supernatural_74"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_75.jpg",
    "filename": "NJ_Supernatural_75"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_76.jpg",
    "filename": "NJ_Supernatural_76"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_77.jpg",
    "filename": "NJ_Supernatural_77"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_78.jpg",
    "filename": "NJ_Supernatural_78"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_79.jpg",
    "filename": "NJ_Supernatural_79"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_80.jpg",
    "filename": "NJ_Supernatural_80"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_81.jpg",
    "filename": "NJ_Supernatural_81"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_82.jpg",
    "filename": "NJ_Supernatural_82"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_83.jpg",
    "filename": "NJ_Supernatural_83"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_84.jpg",
    "filename": "NJ_Supernatural_84"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_85.jpg",
    "filename": "NJ_Supernatural_85"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_86.jpg",
    "filename": "NJ_Supernatural_86"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_87.jpg",
    "filename": "NJ_Supernatural_87"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_88.jpg",
    "filename": "NJ_Supernatural_88"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_89.jpg",
    "filename": "NJ_Supernatural_89"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_90.jpg",
    "filename": "NJ_Supernatural_90"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_91.jpg",
    "filename": "NJ_Supernatural_91"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_92.jpg",
    "filename": "NJ_Supernatural_92"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_93.jpg",
    "filename": "NJ_Supernatural_93"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_94.jpg",
    "filename": "NJ_Supernatural_94"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_95.jpg",
    "filename": "NJ_Supernatural_95"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_96.jpg",
    "filename": "NJ_Supernatural_96"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_97.jpg",
    "filename": "NJ_Supernatural_97"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_98.jpg",
    "filename": "NJ_Supernatural_98"
  },
  {
    "url" : "/imgs/supernatural/photo/NJ_Supernatural_99.jpg",
    "filename": "NJ_Supernatural_99"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_37.jpg",
    "filename": "NJ_Supernatural_37"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_38.jpg",
    "filename": "NJ_Supernatural_38"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_39.jpg",
    "filename": "NJ_Supernatural_39"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_40.jpg",
    "filename": "NJ_Supernatural_40"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_41.jpg",
    "filename": "NJ_Supernatural_41"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_42.jpg",
    "filename": "NJ_Supernatural_42"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_43.jpg",
    "filename": "NJ_Supernatural_43"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_44.jpg",
    "filename": "NJ_Supernatural_44"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_45.jpg",
    "filename": "NJ_Supernatural_45"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_46.jpg",
    "filename": "NJ_Supernatural_46"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_47.jpg",
    "filename": "NJ_Supernatural_47"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_48.jpg",
    "filename": "NJ_Supernatural_48"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_49.jpg",
    "filename": "NJ_Supernatural_49"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_50.jpg",
    "filename": "NJ_Supernatural_50"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_51.jpg",
    "filename": "NJ_Supernatural_51"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_52.jpg",
    "filename": "NJ_Supernatural_52"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_53.jpg",
    "filename": "NJ_Supernatural_53"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_54.jpg",
    "filename": "NJ_Supernatural_54"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_55.jpg",
    "filename": "NJ_Supernatural_55"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_56.jpg",
    "filename": "NJ_Supernatural_56"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_57.jpg",
    "filename": "NJ_Supernatural_57"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_58.jpg",
    "filename": "NJ_Supernatural_58"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_59.jpg",
    "filename": "NJ_Supernatural_59"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_60.jpg",
    "filename": "NJ_Supernatural_60"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_61.jpg",
    "filename": "NJ_Supernatural_61"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_62.jpg",
    "filename": "NJ_Supernatural_62"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_63.jpg",
    "filename": "NJ_Supernatural_63"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_64.jpg",
    "filename": "NJ_Supernatural_64"
  },
  {
    "url": "/imgs/supernatural/photo/NJ_Supernatural_65.jpg",
    "filename": "NJ_Supernatural_65"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_1.jpg",
    "fileName":"NJ_Supernatural_1"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_2.jpg",
    "fileName":"NJ_Supernatural_2"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_3.jpg",
    "fileName":"NJ_Supernatural_3"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_4.jpg",
    "fileName":"NJ_Supernatural_4"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_5.jpg",
    "fileName":"NJ_Supernatural_5"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_6.jpg",
    "fileName":"NJ_Supernatural_6"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_7.jpg",
    "fileName":"NJ_Supernatural_7"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_8.jpg",
    "fileName":"NJ_Supernatural_8"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_9.jpg",
    "fileName":"NJ_Supernatural_9"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_10.jpg",
    "fileName":"NJ_Supernatural_10"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_11.jpg",
    "fileName":"NJ_Supernatural_11"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_12.jpg",
    "fileName":"NJ_Supernatural_12"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_13.jpg",
    "fileName":"NJ_Supernatural_13"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_14.jpg",
    "fileName":"NJ_Supernatural_14"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_15.jpg",
    "fileName":"NJ_Supernatural_15"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_16.jpg",
    "fileName":"NJ_Supernatural_16"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_17.jpg",
    "fileName":"NJ_Supernatural_17"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_18.jpg",
    "fileName":"NJ_Supernatural_18"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_19.jpg",
    "fileName":"NJ_Supernatural_19"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_20.jpg",
    "fileName":"NJ_Supernatural_20"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_21.jpg",
    "fileName":"NJ_Supernatural_21"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_22.jpg",
    "fileName":"NJ_Supernatural_22"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_23.jpg",
    "fileName":"NJ_Supernatural_23"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_24.jpg",
    "fileName":"NJ_Supernatural_24"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_25.jpg",
    "fileName":"NJ_Supernatural_25"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_26.jpg",
    "fileName":"NJ_Supernatural_26"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_27.jpg",
    "fileName":"NJ_Supernatural_27"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_28.jpg",
    "fileName":"NJ_Supernatural_28"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_29.jpg",
    "fileName":"NJ_Supernatural_29"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_30.jpg",
    "fileName":"NJ_Supernatural_30"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_31.jpg",
    "fileName":"NJ_Supernatural_31"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_32.jpg",
    "fileName":"NJ_Supernatural_32"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_33.jpg",
    "fileName":"NJ_Supernatural_33"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_34.jpg",
    "fileName":"NJ_Supernatural_34"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_35.jpg",
    "fileName":"NJ_Supernatural_35"
  },
  {
    "url":"/imgs/supernatural/photo/NJ_Supernatural_36.jpg",
    "fileName":"NJ_Supernatural_36"
  }
]

const PhotoPopup2Container = props => {
  
  const { onClickBtnClosePopup, downloadUrl, setDownloadUrl } = props;
  
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  const slideBtnPrevRef = useRef(null);
  
  const slideBtnNextRef = useRef(null);
  
  const swiperParams = {
    // clickable: true,
    slidesPerView: 1,
    modules: [Navigation],
    navigation: {
      prevEl: '.btn-slide-prev',
      nextEl: '.btn-slide-next'
    },
    afterChange: (_, newIndex) => {
      console.log('afterChange');
      console.log(newIndex);
    }
  }
  
  const onAfterInitSlick = (evt) => {
    console.log('onAfterInit')
    console.log(evt);
  }
  const onSlideChangeSlick = (evt) => {
    // console.log(evt);
    // console.log('activeIndex', evt.activeIndex);
    // console.log('previousIndex', evt.previousIndex);
    // console.log('realIndex', evt.realIndex);
    
    // setCurrentImageIndex(evt.realIndex);
    
    console.log(window.location);
    
    const url = window.location.origin + imageList[evt.realIndex].url;
    
    setDownloadUrl(url);
  }
  
  return (
    <PhotoPopup2Component
      onClickBtnClosePopup={onClickBtnClosePopup}
      
      onAfterInitSlick={onAfterInitSlick}
      onSlideChangeSlick={onSlideChangeSlick}
      
      downloadUrl={downloadUrl}
      
      swiperParams={swiperParams}
      imageList={imageList}
      slideBtnPrevRef={slideBtnPrevRef}
      slideBtnNextRef={slideBtnNextRef}
    />
  )
}

export default PhotoPopup2Container;