import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isLogin } from '../../../helpers/authHelper';

const RouteWithLayout = props => {
  const { layout: Layout,
    component: Component,
    containerClassName,
    needWhiteStatusBar,
    barTime,
    ...rest } = props;

  const authCheck = _ => {

    if (process.env.REACT_APP_ENV === 'dev' && !isLogin()) {
      alert('로그인이 필요합니다.')
      return false
    }
    return true
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        Layout && authCheck() ?
          <Layout
            containerClassName={containerClassName}
            needWhiteStatusBar={needWhiteStatusBar}
            barTime={barTime}>
            <Component {...matchProps} {...props} />
          </Layout>
          :
          !Layout ?
            <Component {...matchProps} {...props} />
            : <Redirect to="/login" />
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  layout: PropTypes.any,
  component: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
