import React, { useState, useEffect, useRef } from 'react';
import SubHeader from "./subHeader";

const DetailComponent = ({ isList, setIsList, isDetail, setIsDetail, isPlaying, setIsPlaying, onPlayPause, selectedVideos, statusDark, setStatusDark, audioRef }) => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [showMessagePopup, setShowMessagePopup] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const videoRef = useRef(null);
    
    const handleVideoButtonClick = (index) => {
        setCurrentVideoIndex(prevIndex => {
            if (prevIndex === index) {
                return -1;
            }
            return index;
        });
    };
    
    const handleVideoEnded = () => {
        if (currentVideoIndex === selectedVideos.length - 1) {
            setShowMessagePopup(true);
        }
    };
    
    const goBack = () => {
        setShowMessagePopup(false);
        setIsList(true);
        setIsDetail(false);
        setStatusDark(false);
    }
    
    const handleShareClick = () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
            setShowMessagePopup(false);
            setShowToast(true);
            setTimeout(() => setShowToast(false), 1500);
        });
    };
    
    useEffect(() => {
        const manageMediaPlayback = async () => {
            document.querySelector('.mockup').classList.add('video');
            
            if (videoRef.current && audioRef.current) {
                const beforeAudioStatus = audioRef.current.paused;
                
                try {
                    await videoRef.current.play();
                    videoRef.current.pause();
                    
                    await audioRef.current.play();
                    audioRef.current.pause();
                    
                    if (!beforeAudioStatus) {
                        await videoRef.current.play();
                        await audioRef.current.play();
                        setIsPlaying(true);
                    } else {
                        await videoRef.current.play();
                    }
                } catch (error) {
                    console.error('Error during media playback:', error);
                }
            }
        };
        
        manageMediaPlayback();
    }, [currentVideoIndex]);
    
    return (
        <>
            <SubHeader
                isList={isList}
                setIsList={setIsList}
                isDetail={isDetail}
                setIsDetail={setIsDetail}
                isPlaying={isPlaying}
                onPlayPause={onPlayPause}
                statusDark={statusDark}
                setStatusDark={setStatusDark}
            />
            
            <div className="video-container">
                <video
                    ref={videoRef}
                    src={selectedVideos[currentVideoIndex]}
                    loop={currentVideoIndex > 0 && currentVideoIndex < 5}
                    onEnded={handleVideoEnded}
                    playsInline
                />
            </div>
            
            <div className="video-buttons">
                <button type="button" className="btn" onClick={() => handleVideoButtonClick(1)}>
                    <img src="/imgs/common/video-icon-1.png" alt="walk"/>
                </button>
                <button type="button" className="btn" onClick={() => handleVideoButtonClick(2)}>
                    <img src="/imgs/common/video-icon-2.png" alt="jump"/>
                </button>
                <button type="button" className="btn" onClick={() => handleVideoButtonClick(3)}>
                    <img src="/imgs/common/video-icon-3.png" alt="dance"/>
                </button>
                <button type="button" className="btn" onClick={() => handleVideoButtonClick(4)}>
                    <img src="/imgs/common/video-icon-4.png" alt="pose"/>
                </button>
                <button type="button" className="btn" onClick={() => handleVideoButtonClick(5)}>
                    <img src="/imgs/common/video-icon-5.png" alt="out"/>
                </button>
            </div>
            
            {showMessagePopup && (
                <div className="message-popup">
                    <p className="title">
                        혼자 보기 아깝다면<br/>친구들에게도 알려주세요!
                    </p>
                    <p className="text">Share a link!</p>
                    
                    <div className="message-popup-btn-wrap">
                        <button className="btn" onClick={goBack}>
                            돌아가기
                            <span>New Video</span>
                        </button>
                        <button className="btn" onClick={handleShareClick}>
                            공유하기
                            <span>Share</span>
                        </button>
                    </div>
                </div>
            )}
            
            {showToast &&
                (
                    <div className="toast-message">
                        <p className="title">링크가 복사되었습니다.</p>
                        <p className="text">Link Copied.</p>
                    </div>
                )
            }
        </>
    );
}

export default DetailComponent;
