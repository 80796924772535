import React, { useEffect, useState } from 'react';
import { isAgree, setIsAgree } from '../../../helpers/agreeHelper';
import { Link } from 'react-router-dom'
import * as LanguageCodeHelper from 'helpers/languageCodeHelper';

const CookiePopupComponent = (props) => {
    const [languageCode, setLanguageCode] = useState('en');

    const [stepNum, setStepNum] = useState(1);
    const [isOpenedAgreeAll, setIsOpenedAgreeAll] = useState(false);
    const [isOpenedAgreePerformance, setIsOpenedAgreePerformance] = useState(false);

    const [isCheckedAgree, setIsCheckedAgree] = useState(false);

    const [isAgreeAll, setIsAgreeAll] = useState(isAgree());

    useEffect(_ => {
        const languageCode = LanguageCodeHelper.getBrowserLanguageCode('en');
        setLanguageCode(languageCode);
    }, [])

    const onToggleAgree = (agreeType) => {
        if (agreeType === 'all') {
            setIsOpenedAgreeAll(!isOpenedAgreeAll);
            setIsOpenedAgreePerformance(false);
        } else if (agreeType === 'performance') {
            setIsOpenedAgreePerformance(!isOpenedAgreePerformance);
            setIsOpenedAgreeAll(false);
        }
    }
    const onChangeCheckbox = (evt, agreeType) => {
        setIsCheckedAgree(!isCheckedAgree);
    }

    const onClickBtnConfigure = (evt) => {
        setStepNum(2);
    }

    const onClickBtnSaveAgreeAll = (evt) => {
        setIsAgree();
        setIsAgreeAll(true);
    }

    const onClickBtnSaveAgree = (evt) => {
        console.log('save');
        if (isCheckedAgree) {
            setIsAgree();
            setIsAgreeAll(true);
        }
    }

    return (
        <>
            {!isAgreeAll
                ?
                <div className={`cookie-popup ${languageCode}`}>
                    <div className={`wrap wrap-${stepNum}`}>
                        {
                            stepNum === 1 ?
                                <div className="step step-1">
                                    <div className="text">
                                        <div className="ko">
                                            고객 경험 개선 및 이용자 통계 분석 목적의 쿠키 활용 동의
                                        </div>
                                        <div className="en">
                                            Cookie Usage Agreement for User Experience<br />
                                            Enhancement and Statistical Analysis
                                        </div>
                                    </div>
                                    <Link to="/agree">
                                        <div className="ko">
                                            자세히보기
                                        </div>
                                        <div className="en">
                                            Detail
                                        </div>
                                    </Link>
                                    <div className="btn-list">
                                        <ul>
                                            <li>
                                                <div className="btn"
                                                    onClick={onClickBtnConfigure}>
                                                    <div className="ko">
                                                        선택동의
                                                    </div>
                                                    <div className="en">
                                                        Configure
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="btn"
                                                    onClick={onClickBtnSaveAgreeAll}>
                                                    <div className="ko">
                                                        전체동의
                                                    </div>
                                                    <div className="en">
                                                        Accept All
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                : null}
                        {stepNum === 2 ?
                            <div className="step step-2">
                                <div className="text">
                                    <div className="ko">쿠키 수집 동의</div>
                                    <div className="en">Consent to Collection of Cookies</div>
                                </div>
                                <div className="desc">
                                    <div className="ko">더 좋은 서비스 제공을 위해 쿠키 수집에 동의해 주세요.</div>
                                    <div className="en">
                                        Please allow Newjeans to collect cookies for<br />
                                        a better experience
                                    </div>
                                </div>
                                <div className="agree-list">
                                    <ul>
                                        <li className={isOpenedAgreeAll ? "on" : ""}>
                                            <div className="title">
                                                <div className="left">
                                                    <label className="checkbox">
                                                        <input type="checkbox"
                                                            checked={isCheckedAgree}
                                                            onChange={evt => { onChangeCheckbox(evt, 'all') }} />
                                                        <div className="shape"></div>
                                                    </label>
                                                </div>
                                                <div className="center" onClick={evt => onToggleAgree('all')}>
                                                    <div className="ko">전체동의</div>
                                                    <div className="en">Accept All</div>
                                                </div>
                                                <div className="arrow">
                                                    <img className="arrow-down" src="../imgs/common/arrow-down.png" alt="arrow-down"/>
                                                    <img className="arrow-down-disabled" src="../imgs/common/arrow-down-disabled.png" alt="arrow-down-disabled"/>
                                                    <img className="arrow-up" src="../imgs/common/arrow-up.png" alt="arrow-up"/>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="ko">
                                                    HYBE와 그 파트너사는 쿠키 및 유사 기술(통칭하여 "기술")을 사용하여 사용자의 기기에 정보를 저장하고 접근합니다. 안전하고, 제대로 작동하며, 신뢰할 수 있는 웹사이트를 제공하기 위해 이들 중 일부 기술은
                                                    필수적입니다. 당사는 최상의 사용자 환경을 제공하기 위해 선택적/비필수 기술을 설정하고자 합니다. 선택적 기술은
                                                    당사 웹사이트 사용자의 통계를 측정하고, 사용자의 프로필을 기반으로 타사 사이트에 맞춤형 광고를 표시하고, 사용자의
                                                    위치를 추적하고, 타겟팅된 마케팅 캠페인을 실행하고, 개인의 사용 패턴에 따라 당사 웹사이트의 콘텐츠를 개인화할 수
                                                    있게 합니다. 당사는 이러한 기술을 통해 사용자와 당사 웹 사이트 간의 상호 작용, 선호도 및 검색 습관과 같은
                                                    정보를 수집합니다.<br />
                                                    <br />
                                                    이러한 목적으로 기술을 사용하는 데 동의하시면 "모두 수락"을 클릭하여 모든 기술의 사용을 수락하세요. 또한 아래와
                                                    같이 어떤 쿠키들을 귀하의 기기에 설정할지 관리할 수 있습니다. 웹 사이트 하단의 "쿠키 환경 설정" 아이콘을
                                                    클릭하면 언제든지 설정을 변경할 수 있습니다. 선택한 옵션은 12개월 동안 유지됩니다. 자세한 내용은 당사의
                                                    <Link to="/agree">쿠키 정책</Link> 및
                                                    <a href="https://ador.world/privacy.html" target="_blank" rel="noopener noreferrer" >개인정보 보호정책</a>에서도 확인할 수 있습니다.
                                                </div>
                                                <div className="en">
                                                    HYBE, and its partners, use cookies and similar technologies
                                                    (collectively “technologies”) to store and access information on
                                                    your device. Some of these technologies are technically
                                                    essential to provide you with a secure, well-functioning and
                                                    reliable website. We would also like to use
                                                    optional/non-essential technologies to give you the best user
                                                    experience. The optional technologies make it possible to
                                                    analyse the audience of our website, to display personalized
                                                    advertising on third-party sites based on your profile, to track
                                                    your location, to carry out targeted marketing campaigns and to
                                                    personalize the content of our website depending on your usage.
                                                    Through these technologies, we will collect information such as
                                                    the interactions between you and our website, your preferences
                                                    and your browsing habits.<br />
                                                    <br />
                                                    If you are happy for technologies to be used for these purposes,
                                                    click on "Accept All" to accept all of the technologies. You are
                                                    also able to manage which cookies can be used on your device
                                                    below. You can change your preferences at any time by clicking
                                                    on the "Cookie Preferences" icon at the bottom of our website.
                                                    Your choice will be retained for 12 months. Further information
                                                    is also available in our
                                                    <Link href="/agree">Cookie Policy</Link> and our
                                                    <a href="https://ador.world/privacy_en.html" target="_blank" rel="noopener noreferrer">Privacy Policy.</a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="disabled">
                                            <div className="title">
                                                <div className="left">
                                                    <label className="checkbox">
                                                        <input type="checkbox" disabled />
                                                        <div className="shape"></div>
                                                    </label>
                                                </div>
                                                <div className="center">
                                                    <div className="ko">필수 쿠키</div>
                                                    <div className="en">Essential Cookies</div>
                                                </div>
                                                <div className="arrow">
                                                    <img className="arrow-down" src="../imgs/common/arrow-down.png" alt="arrow-down"/>
                                                    <img className="arrow-down-disabled" src="../imgs/common/arrow-down-disabled.png" alt="arrow-down-disabled"/>
                                                    <img className="arrow-up" src="../imgs/common/arrow-up.png" alt="arrow-up"/>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={isOpenedAgreePerformance ? "on" : ""}>
                                            <div className="title">
                                                <div className="left">
                                                <label className="checkbox">
                                                        <input type="checkbox"
                                                            checked={isCheckedAgree}
                                                            onChange={evt => { onChangeCheckbox(evt, 'performance') }} />
                                                        <div className="shape"></div>
                                                    </label>
                                                </div>
                                                <div className="center" onClick={evt => onToggleAgree('performance')}>
                                                    <div className="ko">분석/성능 쿠키</div>
                                                    <div className="en">Analytical/Performance Cookies</div>
                                                </div>
                                                <div className="arrow">
                                                    <img className="arrow-down" src="../imgs/common/arrow-down.png" alt="arrow-down"/>
                                                    <img className="arrow-down-disabled" src="../imgs/common/arrow-down-disabled.png" alt="arrow-down-disabled"/>
                                                    <img className="arrow-up" src="../imgs/common/arrow-up.png" alt="arrow-up"/>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="ko">
                                                    해당 쿠키는 당사가 사용자의 사이트 접근 방법 및 탐색 습관(예: 조회한 페이지, 한 페이지에서 소비한 시간,
                                                    클릭한 콘텐츠)을 이해하는 데 도움을 줍니다. 이를 통해 사이트의 성능 및 설계를 분석하고 잠재적 오류를 감지할 수
                                                    있습니다. 당사는 이 성과 지표들을 이용하여 사이트, 제품, 콘텐츠 및 상품을 지속적으로 개선하고 있습니다.
                                                    <Link to="/agree">VIEW COOKIES</Link>
                                                </div>
                                                <div className="en">
                                                    These cookies allow us to understand how you access the site and
                                                    your browsing habits (e.g. pages viewed, time spent on a page,
                                                    content clicked). They allow us to analyze the performance and
                                                    design of our site and to detect possible errors. Owing to these
                                                    performance indicators, we are constantly improving our site and
                                                    our products, content and offers.
                                                    <Link to="/agree">VIEW COOKIES</Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="disabled">
                                            <div className="title">
                                                <div className="left">
                                                    <label className="checkbox">
                                                        <input type="checkbox" disabled />
                                                        <div className="shape"></div>
                                                    </label>
                                                </div>
                                                <div className="center">
                                                    <div className="ko">기능 및 프로필 쿠키</div>
                                                    <div className="en">Functionality and Profile Cookies</div>
                                                </div>
                                                <div className="arrow">
                                                    <img className="arrow-down" src="../imgs/common/arrow-down.png" alt="arrow-down"/>
                                                    <img className="arrow-down-disabled" src="../imgs/common/arrow-down-disabled.png" alt="arrow-down-disabled"/>
                                                    <img className="arrow-up" src="../imgs/common/arrow-up.png" alt="arrow-up"/>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="disabled">
                                            <div className="title">
                                                <div className="left">
                                                    <label className="checkbox">
                                                        <input type="checkbox" disabled />
                                                        <div className="shape"></div>
                                                    </label>
                                                </div>
                                                <div className="center">
                                                    <div className="ko">광고/타겟팅 쿠키</div>
                                                    <div className="en">Advertising / Targeting Cookies</div>
                                                </div>
                                                <div className="arrow">
                                                    <img className="arrow-down" src="../imgs/common/arrow-down.png" alt="arrow-down" />
                                                    <img className="arrow-down-disabled" src="../imgs/common/arrow-down-disabled.png" alt="arrow-down-disabled" />
                                                    <img className="arrow-up" src="../imgs/common/arrow-up.png" alt="arrow-up"  />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="btn-list">
                                    <ul>
                                        <li>
                                            <div className={`btn disabled`}>
                                                <div className="ko">
                                                    모두 거절
                                                </div>
                                                <div className="en">
                                                    Disagree All
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={`btn ${isCheckedAgree ? '' : 'disabled'}`}
                                                onClick={onClickBtnSaveAgree}>
                                                <div className="ko">
                                                    저장
                                                </div>
                                                <div className="en">
                                                    Save
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
                : null}
        </>
    )
}

export default CookiePopupComponent