import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BunniesCampComponent from './BunniesCampComponent';

const certificateUrlList = {
    '0701': '/imgs/bunnies-camp/bunniescamp_day1_thank_u_bunnies.jpg',
    '0702': '/imgs/bunnies-camp/bunniescamp_day2_love_u_bunnies.jpg',
}

const BunniesCampContainer = props => {
    const { history } = props;
    const [isInit, setIsInit] = useState(false);
    const [certificateUrl, setCertificateUrl] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        const newCertificateUrl = certificateUrlList[id];

        if (!newCertificateUrl) {
            history.push('/');
        } else {
            console.log('newCertificateUrl', newCertificateUrl);
            setCertificateUrl(newCertificateUrl);
            setIsInit(true);
        }
    }, [])

    return (
        <div id='container' className='bunnies-camp'>
            {isInit ?
                <BunniesCampComponent
                    certificateUrl={certificateUrl}
                />
                : null}
        </div>
    )
}

export default BunniesCampContainer;