import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/swiper.min.css"

const PhotoPopupComponent = props => {
    const { swiperParams } = props;
    const { slideBtnPrevRef, slideBtnNextRef, onAfterInitSlick, onSlideChangeSlick} = props;
    const { imageList } = props;
    // const { onClickBtnClosePopup } = props;
    // const { downloadUrl } = props;

    return (
        <div className="popup">
            <div className='con'>
                {/*<a className='btn-download'
                    href={downloadUrl} download={true}>
                    <img src='/imgs/december/icon-download.png' alt='download' />
                </a>
                <div className='btn-close'
                    onClick={onClickBtnClosePopup}>
                    <img src='/imgs/december/icon-close.png' alt='close' />
                </div>*/}
                <Swiper
                    {...swiperParams}
                    className='slider'
                    spaceBetween={10}
                    onAfterInit={onAfterInitSlick}
                    onSlideChange={onSlideChangeSlick}
                    loop={true}>
                    {
                        imageList.map((item, idx) =>
                            <SwiperSlide className='slide'
                                key={idx}
                                /*style={{ backgroundImage: "url('" +  + "')" }}*/
                            >
                                <img src={item.url} alt=""/>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
                <div ref={slideBtnPrevRef} className="btn-slide btn-slide-prev"></div>
                <div ref={slideBtnNextRef} className="btn-slide btn-slide-next"></div>
            </div>
        </div>
    )
}
export default PhotoPopupComponent;