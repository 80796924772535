import React, { useEffect } from 'react';

const SubHeader = ({ isList, setIsList, isDetail, setIsDetail, isPopup, setIsPopup, isPlaying, onPlayPause, downloadUrl, setStatusDark }) => {
    const goBack = () => {
        if ( isList ) {
            setIsList(!isList);
            setStatusDark(false);
        } else if ( isDetail ) {
            setIsList(!isList);
            setIsDetail(!isDetail);
            setStatusDark(false);
        } else if ( isPopup ) {
            setIsPopup(!isPopup);
        }
    }
    
    const handlePlayPause = (e) => {
        onPlayPause();
        
        if ( e.target.tagName === "IMG" ) {
            e.target.parentElement.classList.toggle('stopped');
        } else {
            e.target.classList.toggle('stopped');
        }
    }
    
    useEffect(() => {
        if ( isPlaying ) {
            document.querySelector('.btn-play').classList.remove('stopped');
        } else {
            document.querySelector('.btn-play').classList.add('stopped');
        }
    });
    
    return (
        <>
            <div className="sub-header">
                <button type="button" className="btn-home" onClick={goBack}>
                    {isList || isPopup ?
                        <img src="/imgs/common/btn-home.png" alt="Home"/> :
                        <img src="/imgs/common/btn-back.png" alt="Back"/>
                    }
                </button>
                
                {isDetail ?
                    <img src="/imgs/common/phone-camera.png" className="camera" alt="camera"/>
                    :
                    null
                }
                
                <div className="btn-wrap">
                    {isPopup ?
                        <a href={downloadUrl} className="btn-download" download={true}>
                            <img src="/imgs/common/btn-download.png" alt="이미지 다운로드"/>
                        </a> :
                        null
                    }
                    
                    <button type="button" className="btn-play" onClick={handlePlayPause}>
                        <img src="/imgs/common/music-icon.png" alt="재생/멈춤"/>
                    </button>
                </div>
            </div>
        </>
    );
}

export default SubHeader;