import React from 'react';

const GetUpEventComponent = props => {
    const { imgObj } = props;

    return (
        <main>
            <div className='con'>
                <div className='img pc'>
                    <img src={imgObj['pc']} />
                </div>
                <div className='img mb'>
                    <img src={imgObj['mb']} />
                </div>
            </div>
        </main>
    )
}

export default GetUpEventComponent;