import React from 'react';

import YouTube from 'react-youtube';

const OmgComponent = props => {
    // variables
    const { videoId } = props;

    const options = {

    }

    const onReady = (evt) => {
        console.log('onReady Youtube');
    }

    return (
        <div id='omg-card'>
            <div className='title-text'>
                MESSAGE CARD FROM NEWJEANS
            </div>


            <div className='wrap'>
                <div className='title'>
                    <div className='text'>
                        MESSAGE CARD FROM NEWJEANS
                    </div>
                    <div className='deco-mb'>
                        <div className='col col-l'>
                            <img src='/imgs/omg/mb/deco-l.png' alt='bunnies' />
                        </div>
                        <div className='col col-r'>
                            <img src='/imgs/omg/mb/deco-r.png' alt='bunnies' />
                        </div>
                    </div>
                </div>
                <div className='video-wrap'>
                    <div className="video">
                        <img className='deco-l' src='/imgs/omg/pc/bunnies-l.png' alt='bunnies' />
                        <YouTube className="video"
                            videoId={videoId}
                            opts={options}
                            onReady={onReady}
                        />
                        <img className='deco-r' src='/imgs/omg/pc/bunnies-r.png' alt='bunnies' />
                    </div>
                </div>
                <div className="noti-wrap">
                    {/* <div className="text-wrap">
                        <div className="text en">
                            [Information on Message Card Translations and Voice Message Subtitle Services]<br />
                            The English subtitles and translations for the message cards (YouTube Description Box) will be provided on Wednesday, January 4.<br />
                            Thank you.
                        </div>
                        <div className="text jp">
                            [メッセージカードの翻訳および音声メッセージの字幕サービスに関するご案内]<br />
                            1月6日(金)に日本語の字幕およびメッセージカードの翻訳（YouTube説明欄）サービスが提供される予定です。<br />
                            ありがとうございます。
                        </div>
                        <div className="text jp">
                            [Message Card翻译及音频信息的字幕提供指南]<br />
                            中文字幕与Message Card翻译（YouTube说明栏）将于1月6日（周五）提供。<br />
                            谢谢。
                        </div>
                        <div className="text es">
                            [Aviso sobre la disponibilidad de la traducción de tarjetas de mensaje y los subtítulos para mensajes de audio]<br />
                            Estarán disponibles los subtítulos en español y la traducción de tarjetas de mensaje(Descripción en YouTube) el viernes 6 de enero.<br />
                            Gracias.
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="mb-logo">
                <img src="/imgs/omg/mb/ador-logo.png" alt="logo" />
            </div>
        </div>
    )

}

export default OmgComponent;