import React from 'react';
import CookieAgreeComponent from './CookieAgreeComponent'

const CookieAgreeContainer = props => {
    const { history } = props
    return (
        <div className="cookie-agree">
            <CookieAgreeComponent
                history={history}
                {...props} />
        </div>
    );
}

export default CookieAgreeContainer;