import { setCookie, getCookie, removeCookie } from './cookieHelper'

const loginCookieKey = '__NJ_AUTH';

export const setIsLogin = _ => {
    setCookie(loginCookieKey, 'Y');
}

export const isLogin = () => {
    return getCookie(loginCookieKey) === 'Y' ? true : false;
}