import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';

const MainComponent = ({ onPlayPause, isPlaying, onNext, isList, setIsList, isPopup, setIsPopup, popupIndex, setPopupIndex, audioTitle }) => {
    const photoPopupOpen = (index) => {
        setIsPopup(!isPopup);
        setPopupIndex(index);
    }
    
    useEffect(() => {
        if ( isPlaying ) {
            document.querySelector('.btn-play').classList.add('playing');
        } else {
            document.querySelector('.btn-play').classList.remove('playing');
        }
    });
    
    return (
        <>
            <div className="player">
                <div className="player-thumb">
                    <img src="/imgs/common/playing-cover-2.png" alt="Playing Cover"/>
                </div>
                <div className="player-info">
                    <div className="player-info-title">{audioTitle}</div>
                    <div className="player-info-name">NewJeans</div>
                </div>
                <div className="player-buttons">
                    <button className="btn-play btn-has-effect" onClick={onPlayPause}>
                        <img src="/imgs/common/btn-play.png" className="icon-play" alt="재생"/>
                        <img src="/imgs/common/btn-pause.png" className="icon-pause" alt="멈춤"/>
                    </button>
                    <button className="btn-next btn-has-effect" onClick={onNext}>
                        <img src="/imgs/common/btn-next.png" alt="다음곡 재생"/>
                    </button>
                </div>
            </div>

            <Swiper
                className="intro-card-swiper"
                spaceBetween={12}
                slidesPerView={1.1}
            >
                <SwiperSlide>
                    <a href="https://youtu.be/ZncbtRo7RXs" target="_blank" rel="noopener noreferrer" className="intro-card intro-card--2">
                        <div className="intro-card-thumb">
                            <img src="/imgs/common/card-thumb-2.jpg" alt="How Sweet Thumbanil"/>
                        </div>
                        <div className="intro-card-desc">
                            <p className="field">
                                New Album
                            </p>
                            <p className="title">
                                ‘Supernatural’ 발매!
                            </p>
                            <p className="text">
                                My feeling’s getting deeper <img src="/imgs/common/icon-music.svg" className="icon-music" /><br />
                                ‘Supernatural’ Music Video 보러 가기!
                            </p>
                        </div>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="intro-card" onClick={() => setIsList(!isList)}>
                        <div className="intro-card-thumb">
                            <img src="/imgs/common/card-thumb.jpg" alt="How Sweet Thumbanil"/>
                        </div>
                        <div className="intro-card-desc">
                            <p className="field">
                                New Video
                            </p>
                            <p className="title">
                                How Sweet, NewJeans!
                                <img className="icon" src="/imgs/common/intro-card-icon.png" alt="Candy Icon"/>
                            </p>
                            <p className="text">
                                신곡과 함께 뉴진스 멤버들의 새로운 영상을 <br/>확인하세요.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

            <div className="app-buttons">
                <button type="button" className="btn btn-has-effect" onClick={() => photoPopupOpen(0)}>
                    <img src="/imgs/common/app-icon-1.png" alt="How Sweet"/>
                </button>
                <button type="button" className="btn btn-has-effect" onClick={() => photoPopupOpen(1)}>
                    <img src="/imgs/common/app-icon-2.png" alt="Supernatural"/>
                </button>
                <a href="https://phoning.onelink.me/KG15/7uhc8jf6" className="btn btn-has-effect" target="_blank"
                   rel="noopener noreferrer">
                    <img src="/imgs/common/app-icon-3.png" alt="Phoning"/>
                    <img src="/imgs/common/app-icon-badge.png" className="badge" alt="Go!"/>
                </a>
            </div>
            
            <div className="sns-links">
                <a href="https://www.youtube.com/c/NewJeans_official" className="link" target="_blank" rel="noopener noreferrer">
                    <img src="/imgs/common/sns-icon-1.png" alt="Youtube"/>
                </a>
                <a href="https://x.com/NewJeans_ADOR" className="link" target="_blank" rel="noopener noreferrer">
                    <img src="/imgs/common/sns-icon-2.png" alt="X"/>
                </a>
                <a href="https://www.instagram.com/newjeans_official/" className="link" target="_blank" rel="noopener noreferrer">
                    <img src="/imgs/common/sns-icon-3.png" alt="Instagram"/>
                </a>
                <a href="https://www.facebook.com/official.newjeans" className="link" target="_blank" rel="noopener noreferrer">
                    <img src="/imgs/common/sns-icon-4.png" alt="Facebook"/>
                </a>
                <Link className="privacy-policy" to="/agree">쿠키사용방침</Link>
            </div>
        </>
    );
}

export default MainComponent;