const acceptedLanguageList = [
    'en', 'ko'
]

export const getBrowserLanguageCode = (defaultLanguageCode = 'en') => {
    const language = navigator.language || navigator.userLanguage;
    const languageCode = language.split('-')[0];
    if (acceptedLanguageList.includes(languageCode)) {
        return languageCode;
    } else {
        return defaultLanguageCode;
    }
}